import React from "react"
import { Row, Col } from "react-bootstrap"

export default function JobDetail(props) {
  const tasks = props.tasks.map((task) =>
    <li key={task}>{task}</li>
  );
  const offers = props.offers.map((offer) =>
    <li key={offer}><div dangerouslySetInnerHTML={{ __html: offer }} /></li>
  );
  return (
    <Row className="job-detail mb-5">
      <Col md={12}>
        <div className="job-content">
        <p className="date">{props.date}</p>
        <h2 className="mt-3">{props.title}</h2>
          <p className="job-description">
              {props.desc}
          </p> 
        </div>
      </Col>
      <Col md={6}>
        <div className="task">
          <h4>Was erwartet dich?</h4>
          <ul>
              {tasks}
          </ul>
        </div>
      </Col>
      <Col md={6}>
        <div className="offer">
          <h4>Das bieten wir:</h4>
          <ul>
              {offers}
          </ul>
        </div>
      </Col>
        <Col md={12}>
            <p>
              <strong>Bewerbungen</strong> und Rückfragen zum Stellenangebot bitte per E-Mail an <strong><a href={`mailto:${props.inviteMail}`}>{props.inviteMailName}</a></strong>.
            </p>
        </Col>
    </Row>
  )
}


