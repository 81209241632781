import React from "react";
import JobDetail from "../molecules/jobDetail";

const jobs = [
  {
    order: 3,
    active: false,
    date: "ab sofort",
    title: "Werkstudent*in (m/w/d) Agile Testing",
    desc: "Du bist momentan in Vollzeit an einer Hochschule oder einer Universität immatrikuliert und studierst Informationstechnik oder Informatik, Informationsdesign, Human Computer Interaction oder in einem vergleichbar relevanten Studiengang? Du bist gespannt, unsere Produkte bis ins kleinste Detail kennenzulernen und technisch verstehen zu lernen und neben der funktionalen Qualitätskontrolle auch Design-Veränderungen im Blick zu behalten? Du hast im besten Fall schon mit Ticketsystemen gearbeitet und idealerweise Erfahrungen mit Testframeworks wie z.B. Browserstack, Cypress oder Selenium? Dann suchen wir dich!",
    tasks: [
      "Du unterstützt das Produkt - und Tech - Team zunächst auf 1 Jahr befristet und bist für das Testing und die Qualitätskontrolle verantwortlich, um die Qualität neuer Features zu gewährleisten",
      "Du erstellst Tests, führst diese aus und bist zuständig für Freigabeempfehlungen",
      "Du unterstützt bei der Erstellung von automatisierten Tests und Weiterentwicklung je nach individuellen Fähigkeiten",
      "Du hilfst aktiv mit, die Qualitätsprozesse von MissionMe weiter zu verbessern",
      "Du begleitest das Team bei der Weiterentwicklung und user - zentrierten Optimierung unserer Produkte",
    ],
    offers: [
      "Wir sind ein sympathisches Team mit viel Erfahrung in der Welt von Startups und einem umfangreichen digitalen Background.",
      "Wir leben flexibles Arbeiten (nicht erst seit Corona): Du kannst deine Arbeitszeit selbst mitgestalten und flexibel einteilen.",
      "Wir haben einen großartigen Arbeitsplatz in unserem Open Space im Hamburger Schanzenviertel.",
      "Wir arbeiten mit einem modernen Tech-Stack, setzen spannende Produkte um und haben Platz für eigene Ideen.",
      "Wir sind ein kleines Team - du wirst alle Bereiche kennen lernen, die es braucht, um digitale Produkte zu entwerfen, umzusetzen und damit Geld zu verdienen.",
    ],
    inviteMail: "jobs@missionme.de",
    inviteMailName: "Jochen Kramer",
  },
  {
    order: 2,
    active: true,
    date: "alle 3-6 Monate",
    title: "Praktikant*in (m/w/d) im Produktmanagement von MissionMe",
    desc: "Du befindest dich derzeit im Gap Year, im Master oder am Ende deines Bachelorstudiums im Bereich Produktmanagement, Human Computer Interaction, Informationsdesign, UX-Design oder einem vergleichbaren relevanten Studiengang? Du verfügst über Kenntnisse im Bereich User Experience, Produktmanagement oder User Interface Design und konntest idealerweise bereits praktische Erfahrung im Bereich Research oder Quality Assurance sammeln? Du hast bereits Erfahrung in der Produktentwicklung von digitalen Produkten gesammelt, idealerweise von mobilen Apps oder Webapps? Du begeisterst dich für die Arbeit in interdisziplinären Teams und hast Freude dabei, die Sprachen aller Bereiche, z.B. Tech, Business und Marketing, sprechen zu lernen? Du hast Freude daran, Produkte bis ins kleinste Detail zu kennen und technisch verstehen zu lernen sowie das Nutzerverhalten zu analysieren? Dann suchen wir dich!",
    tasks: [
      "Du begleitest das Team bei der Weiterentwicklung und user-zentrierten Optimierung unserer Produkte.",
      "Du lernst die Arbeit mit einer Entwicklungs-Roadmap kennen, die Bedürfnisse aus Produkt, Business und Technologie berücksichtigen muss.",
      "Du unterstützt das Produkt-Team im operativen Alltag und lernst dabei die verschiedenen Aufgaben und Themen kennen, die an der Schnittstelle zwischen Entwicklung, Business und Marketing entstehen.",
      "Du hilfst unsere Produkte zu testen und die Qualität neuer Features zu gewährleisten.",
      "Du rekrutierst User der entsprechenden Zielgruppen für den User Research und hilfst, die Testings (remote) zu organisieren.",
      "Du führst User-Testings durch und hilfst, anhand der Ergebnisse Ableitungen für die Produkte zu treffen",
      "Du führst Datenanalysen mit unseren Tracking-Tools durch, um Ableitungen für die Weiterentwicklung der Produkte zu treffen",
    ],
    offers: [
      "Wir sind ein sympathisches Team mit viel Erfahrung in der Welt von Startups und einem umfangreichen digitalen Background.",
      "Wir leben flexibles Arbeiten (nicht erst seit Corona): Du kannst deine Arbeitszeit in deinem Praktikum selbst mitgestalten und flexibel einteilen.",
      "Mobile Office ist bei uns die Regel, du kannst also von überall in Deutschland aus arbeiten.",
      "Bei regelmäßigen Team-Tagen in Hamburg kommt auch der persönliche Austausch nicht zu kurz.",
      "Wir arbeiten mit einem modernen Tech-Stack, setzen spannende Produkte um und haben Platz für eigene Ideen.",
      "Wir sind ein kleines Team - du wirst alle Bereiche kennen lernen, die es braucht, um digitale Produkte zu entwerfen, umzusetzen und damit Geld zu verdienen.",
    ],
    inviteMail: "jobs@missionme.de",
    inviteMailName: "Anna Wicher",
  },
  {
    order: 1,
    active: false,
    date: "ab sofort",
    title: "Teamassistenz bei MissionMe (w/m/d)",
    desc: "Du behältst immer den Überblick und die Ruhe und hast Freude daran, Dinge zu organisieren und besser zu machen? Du kannst gut kommunizieren und triffst dabei intern den richtigen Ton, hast diplomatisches Geschick und Durchsetzungsvermögen? Du kannst gut mit den gängigen Office-Anwendungen umgehen (Excel ist besonders hilfreich)? Du hast fließende Deutsch- und Englischkenntnisse in Wort und Schrift? Du kommst aus dem Bereich, oder möchtest als Quereinsteiger:in durchstarten? Dann suchen wir dich und freuen uns auf deine Bewerbungsunterlagen (inkl. Motivationsschreiben)!",
    tasks: [
      "Als Teamassistenz erfüllst du bei uns eine wichtige Position in Auftragsabwicklung und Arbeitsabläufen:",
      "Du behältst den Überblick im Team und packst da mit an, wo es gerade klemmt",
      "Du unterstützt uns bei unser Arbeit mit Lieferanten und Dienstleistern, stellst Rechnungen und bezahlst sie",
      "Du bist unser Draht ins Unternehmen – und erste:r Ansprechpartner:in bei Rückfragen in Richtung Rechnungswesen, HR und anderen Abteilungen",
      "Du bist in administrativen Fragen Ansprechpartner:in für unsere externen Dienstleister:innen",
      "Du findest Lösungen, damit die Arbeit im Team besser gelingt und kannst neue Prozesse aufsetzen, wo es nötig wird",
      "Du kannst zeitlich und örtlich so arbeiten, wie es für dich gut funktioniert – z.B. weil du nach einer Arbeitspause wieder langsam ins Berufsleben einsteigen möchtest (Remote First und Flexibilität bei der eingebrachten Arbeitszeit)",
      "Du startest zunächst befristet auf 12 Monate in Teilzeit (16 Std./Woche)",
    ],
    offers: [
      "Wir sind ein sympathisches Team mit viel Erfahrung in der Welt von Startups und einem umfangreichen digitalen Background.",
      "Wir leben flexibles Arbeiten (nicht erst seit Corona): Du kannst deine Arbeitszeit selbst mitgestalten und flexibel einteilen.",
      "Mobile Office ist bei uns die Regel, du kannst also von überall in Deutschland aus arbeiten.",
      "Bei regelmäßigen Team-Tagen in Hamburg kommt auch der persönliche Austausch nicht zu kurz.",
      "Wir setzen spannende Produkte um und haben Platz für eigene Ideen.",
      "Wir sind ein kleines, vielfältiges Team - Zusammenarbeit auf Augenhöhe wird bei uns großgeschrieben.",
    ],
    inviteMail: "jobs@missionme.de",
    inviteMailName: "Steffen Horstmannshoff",
  },
];

export const JobsList = () => {
  return jobs
    .filter((job) => job.active)
    .sort((a, b) => a.order - b.order)
    .map((job, index) => (
      <JobDetail
        key={index}
        date={job.date}
        title={job.title}
        desc={job.desc}
        tasks={job.tasks}
        offers={job.offers}
        inviteMail={job.inviteMail}
        inviteMailName={job.inviteMailName}
      />
    ));
};
