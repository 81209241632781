import React from "react"
import { Container, Row, Col } from "react-bootstrap"

import SEO from "../components/templates/seo"
import Layout from "../components/templates/layout"

import Hero from "../components/organisms/hero"
import { JobsList } from "../components/organisms/jobsList"

import { pushClickEventToDataLayer } from "../components/atoms/gtmHelper"

const JobsPage = () => (
  <Layout pageInfo={{ pageName: "jobs" }}>
    <SEO title="MissionMe - Jobs" />
    <Container fluid="md">
      <Hero
        showDots
        headingPreHeadline="Jobs"
        headingHeadline="Mach MissionMe zu deiner Aufgabe"
        introHeadline="Wie klingt das für dich?"
        introText="Du stehst auf tolles Design und eine einwandfreie UX? Du willst Produkte entwickeln, die Mehrwert schaffen und gleichzeitig Spaß machen? Du hast Lust auf frische Gründerluft mit einem stabilen Konzern im Hintergrund? Kleine Teams, flache Hierarchien und echte Leidenschaft lassen dein Herz schneller schlagen?
        Dann stöbere dich durch unsere offenen Stellen oder bewirb dich initiativ. Wir freuen uns von dir zu hören!"
        introImg={
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 538.583 396.85">
            <g transform="translate(1576.021 -714.424)">
              <rect
                width="2.501"
                height="173.484"
                transform="translate(-1401.21 770.324)"
                fill="#662483"
                className="line"
              />
              <rect
                width="2.501"
                height="173.484"
                transform="translate(-1389.168 770.324)"
                fill="#662483"
                className="line"
              />
              <rect
                width="2.501"
                height="173.484"
                transform="translate(-1377.126 770.324)"
                fill="#662483"
                className="line"
              />
              <rect
                width="2.501"
                height="173.484"
                transform="translate(-1365.083 770.324)"
                fill="#662483"
                className="line"
              />
              <rect
                width="2.501"
                height="173.484"
                transform="translate(-1353.04 770.324)"
                fill="#662483"
                className="line"
              />
              <rect
                width="2.501"
                height="173.484"
                transform="translate(-1340.998 770.324)"
                fill="#662483"
                className="line"
              />
              <rect
                width="2.501"
                height="173.484"
                transform="translate(-1328.955 770.324)"
                fill="#662483"
                className="line"
              />
              <rect
                width="2.501"
                height="173.484"
                transform="translate(-1316.913 770.324)"
                fill="#AE3D3C"
                className="line"
              />
              <rect
                width="2.501"
                height="173.484"
                transform="translate(-1304.871 770.324)"
                fill="#AE3D3C"
                className="line"
              />
              <rect
                width="2.501"
                height="173.484"
                transform="translate(-1292.829 770.324)"
                fill="#AE3D3C"
                className="line"
              />
              <rect
                width="2.501"
                height="173.484"
                transform="translate(-1280.787 770.324)"
                fill="#AE3D3C"
                className="line"
              />
              <rect
                width="2.501"
                height="173.484"
                transform="translate(-1268.744 770.324)"
                fill="#AE3D3C"
                className="line"
              />
              <rect
                width="2.501"
                height="173.484"
                transform="translate(-1256.701 770.324)"
                fill="#AE3D3C"
                className="line"
              />
              <rect
                width="2.501"
                height="173.484"
                transform="translate(-1244.659 770.324)"
                fill="#AE3D3C"
                className="line"
              />
              <rect
                width="2.501"
                height="173.484"
                transform="translate(-1232.617 770.324)"
                fill="#AE3D3C"
                className="line"
              />
              <rect
                width="2.501"
                height="173.484"
                transform="translate(-1220.575 770.324)"
                fill="#AE3D3C"
                className="line"
              />
              <rect
                width="2.501"
                height="173.484"
                transform="translate(-1208.532 770.324)"
                fill="#AE3D3C"
                className="line"
              />
              <rect
                width="2.501"
                height="173.484"
                transform="translate(-1196.49 770.324)"
                fill="#AE3D3C"
                className="line"
              />
              <rect
                width="2.501"
                height="173.484"
                transform="translate(-1184.448 770.324)"
                fill="#AE3D3C"
                className="line"
              />
              <rect
                width="2.501"
                height="173.484"
                transform="translate(-1172.405 770.324)"
                fill="#AE3D3C"
                className="line"
              />
              <rect
                width="2.501"
                height="173.484"
                transform="translate(-1160.362 770.324)"
                fill="#AE3D3C"
                className="line"
              />
              <rect
                width="2.501"
                height="173.484"
                transform="translate(-1148.32 770.324)"
                fill="#AE3D3C"
                className="line"
              />
              <rect
                width="2.501"
                height="173.484"
                transform="translate(-1136.278 770.324)"
                fill="#AE3D3C"
                className="line"
              />
              <rect
                width="2.501"
                height="173.484"
                transform="translate(-1124.236 770.324)"
                fill="#AE3D3C"
                className="line"
              />
              <rect
                width="2.501"
                height="173.484"
                transform="translate(-1112.194 770.324)"
                fill="#AE3D3C"
                className="line"
              />
              <rect
                width="2.501"
                height="173.484"
                transform="translate(-1100.151 770.324)"
                fill="#AE3D3C"
                className="line"
              />
              <rect
                width="2.501"
                height="173.484"
                transform="translate(-1088.109 770.324)"
                fill="#AE3D3C"
                className="line"
              />
              <rect
                width="2.501"
                height="173.484"
                transform="translate(-1076.066 770.324)"
                fill="#AE3D3C"
                className="line"
              />
              <rect
                width="2.501"
                height="173.484"
                transform="translate(-1064.023 770.324)"
                fill="#AE3D3C"
                className="line"
              />
              <rect
                width="2.501"
                height="173.484"
                transform="translate(-1051.981 770.324)"
                fill="#AE3D3C"
                className="line"
              />
              <rect
                width="2.501"
                height="173.484"
                transform="translate(-1039.939 770.324)"
                fill="#AE3D3C"
                className="line"
              />
              <circle
                cx="102.052"
                cy="102.052"
                r="102.052"
                transform="translate(-1463.748 891.377)"
                fill="#f39200"
                className="fullbigcircle"
              />
              <path
                d="M-1187.35,1111.275l-24.557-67.889-72.072,25.238-25.2-71.969-71.984,26.454-25.769-72.388-72.772,24.242-25.686-70.991-67.912,23.388-2.716-7.881,75.675-26.06,25.712,71.069,72.814-24.255,25.681,72.147,72.026-26.47,25.246,72.093,71.99-25.212,27.364,75.649Z"
                fill="#AE3D3C"
                className="zigzagline"
              />
              <circle
                cx="65.056"
                cy="65.056"
                r="65.056"
                transform="translate(-1233.08 714.424)"
                fill="#009e7b"
                className="fullsmallcircle"
              />
              <circle
                cx="3.133"
                cy="3.133"
                r="3.133"
                transform="translate(-1215.344 743.207)"
                fill="#662483"
                className="jobpoint"
              />
              <circle
                cx="3.133"
                cy="3.133"
                r="3.133"
                transform="translate(-1204.297 732.16)"
                fill="#662483"
                className="jobpoint"
              />
              <circle
                cx="3.133"
                cy="3.133"
                r="3.133"
                transform="translate(-1204.297 754.254)"
                fill="#662483"
                className="jobpoint"
              />
              <circle
                cx="3.133"
                cy="3.133"
                r="3.133"
                transform="translate(-1193.25 743.207)"
                fill="#662483"
                className="jobpoint"
              />
              <circle
                cx="3.133"
                cy="3.133"
                r="3.133"
                transform="translate(-1193.25 765.3)"
                fill="#662483"
                className="jobpoint"
              />
              <circle
                cx="3.133"
                cy="3.133"
                r="3.133"
                transform="translate(-1182.204 754.254)"
                fill="#662483"
                className="jobpoint"
              />
              <circle
                cx="3.133"
                cy="3.133"
                r="3.133"
                transform="translate(-1215.344 809.488)"
                fill="#662483"
                className="jobpoint"
              />
              <circle
                cx="3.133"
                cy="3.133"
                r="3.133"
                transform="translate(-1204.297 798.441)"
                fill="#662483"
                className="jobpoint"
              />
              <circle
                cx="3.133"
                cy="3.133"
                r="3.133"
                transform="translate(-1193.25 787.394)"
                fill="#662483"
                className="jobpoint"
              />
              <circle
                cx="3.133"
                cy="3.133"
                r="3.133"
                transform="translate(-1182.204 776.347)"
                fill="#662483"
                className="jobpoint"
              />
              <circle
                cx="3.133"
                cy="3.133"
                r="3.133"
                transform="translate(-1171.157 765.3)"
                fill="#662483"
                className="jobpoint"
              />
              <circle
                cx="3.133"
                cy="3.133"
                r="3.133"
                transform="translate(-1160.11 754.254)"
                fill="#662483"
                className="jobpoint"
              />
              <circle
                cx="3.133"
                cy="3.133"
                r="3.133"
                transform="translate(-1149.063 743.207)"
                fill="#662483"
                className="jobpoint"
              />
              <circle
                cx="3.133"
                cy="3.133"
                r="3.133"
                transform="translate(-1138.017 732.16)"
                fill="#662483"
                className="jobpoint"
              />
              <circle
                cx="3.133"
                cy="3.133"
                r="3.133"
                transform="translate(-1204.297 820.534)"
                fill="#662483"
                className="jobpoint"
              />
              <circle
                cx="3.133"
                cy="3.133"
                r="3.133"
                transform="translate(-1193.25 809.488)"
                fill="#662483"
                className="jobpoint"
              />
              <circle
                cx="3.133"
                cy="3.133"
                r="3.133"
                transform="translate(-1182.204 798.441)"
                fill="#662483"
                className="jobpoint"
              />
              <circle
                cx="3.133"
                cy="3.133"
                r="3.133"
                transform="translate(-1171.157 787.394)"
                fill="#662483"
                className="jobpoint"
              />
              <circle
                cx="3.133"
                cy="3.133"
                r="3.133"
                transform="translate(-1160.11 776.347)"
                fill="#662483"
                className="jobpoint"
              />
              <circle
                cx="3.133"
                cy="3.133"
                r="3.133"
                transform="translate(-1149.063 765.3)"
                fill="#662483"
                className="jobpoint"
              />
              <circle
                cx="3.133"
                cy="3.133"
                r="3.133"
                transform="translate(-1138.017 754.254)"
                fill="#662483"
                className="jobpoint"
              />
              <circle
                cx="3.133"
                cy="3.133"
                r="3.133"
                transform="translate(-1126.97 743.207)"
                fill="#662483"
                className="jobpoint"
              />
              <circle
                cx="3.133"
                cy="3.133"
                r="3.133"
                transform="translate(-1160.11 798.441)"
                fill="#662483"
                className="jobpoint"
              />
              <circle
                cx="3.133"
                cy="3.133"
                r="3.133"
                transform="translate(-1149.063 787.394)"
                fill="#662483"
                className="jobpoint"
              />
              <circle
                cx="3.133"
                cy="3.133"
                r="3.133"
                transform="translate(-1149.063 809.488)"
                fill="#662483"
                className="jobpoint"
              />
              <circle
                cx="3.133"
                cy="3.133"
                r="3.133"
                transform="translate(-1138.017 798.441)"
                fill="#662483"
                className="jobpoint"
              />
              <circle
                cx="3.133"
                cy="3.133"
                r="3.133"
                transform="translate(-1138.017 820.534)"
                fill="#662483"
                className="jobpoint"
              />
              <circle
                cx="3.133"
                cy="3.133"
                r="3.133"
                transform="translate(-1126.97 809.488)"
                fill="#662483"
                className="jobpoint"
              />
            </g>
          </svg>
        }
      />
      <a
        onClick={(e) => pushClickEventToDataLayer(e)}
        href="mailto:jobs@missionme.de"
        id="jobs-initiativ"
        className="btn btn-primary"
      >
        Initiativ bewerben
      </a>
      <Row className="mt-5 pl-3 pr-3">
        <Col md={12}>
          <JobsList />
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default JobsPage
